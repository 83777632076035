export const environment = {
  production: false,
  baseUrl: 'https://dev.tome.gg',
  appVersion: '1.0.1',
  auth: {
    domain: 'dev-phh2u7lm3h45n2fy.us.auth0.com',
    clientId: 'xOWUb7eGFmKmdCobWEcpGswGEI87EL84',
    cacheLocation: 'localstorage',
  },
  hasura: {
    graphql: 'https://tomegg-dev.hasura.app/v1/graphql',
    wss: 'wss://tomegg-dev.hasura.app/v1/graphql',
    api: 'https://tomegg-dev.hasura.app/v1/*'
  }
};
